import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../utils/normalize.css';
import '../utils/css/screen.css';

const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        phoneNumber
      }
    }
    artJEater: file(
      relativePath: { eq: "art-j-eater.png" }
    ) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    effects: allMarkdownRemark(
      sort: { fields: [frontmatter___order,frontmatter___date,], order: ASC },
      filter: { 
        fileAbsolutePath: {regex : "\/effects/"} ,
        frontmatter: { ignore: { ne: false } }
      },
      limit: 99,
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            shorthand
            anim {
              id
              publicURL
              name
            }
          }
        }
      }
    }
  }
`;

const ArtJEaterPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const {
    effects,
  } = data;

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Secret Zone"
        keywords={['animation', 'hand', 'draw', 'secret']}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h1 id="table">
            Art J. Eater&apos;s
            <br />
            secret tips and recipes
            <br />
            <span role="img" aria-label="emoji">🤫</span>
          </h1>
          <p className="home-page-excerpt">
            Shhh! These effects and tricks are for advanced users only. Some may still be buggy.
            <br/>
            Send feedback to
            {' '}
            <strong><Link to="/about">hello.arteater@gmail.com</Link></strong>
.
          </p>
          <table>
            <thead>
              <tr>
                <th>Effect</th>
                <th>Output</th>
              </tr>
            </thead>
            <tbody>
              {effects.edges.map(({ node }) => (
                <tr key={node.fields.slug}>
                  <td>
                    <strong>
                      {node.frontmatter.title}
                    </strong>
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: node.html }} />
                    {/* <span>{node.frontmatter.shorthand}</span> */}
                  </td>
                  <td className="effect-example">
                    <img
                      src={node.frontmatter.anim.publicURL}
                      alt="effect example"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <p>
            Extra secret recipes can be found inside
            {' '}
            <Link to="/sketchbooks">Arteater sketchbooks</Link>
            .
          </p>
        </div>
        <Img
          fluid={data.artJEater.childImageSharp.fluid}
          className="bust-art-j"
          s
        />
      </article>
    </Layout>
  );
};

export default props => (
  <StaticQuery
    query={pageQuery}
    render={data => (
      <ArtJEaterPage location={props.location} data={data} {...props} />
    )}
  />
);
